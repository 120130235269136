import { notification, Row, Space, Tag, Tooltip } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import AuthService from './auth/authService';
import Break from '../components/Break';
import { PublicCSS } from '../cssData';
import history from '../history';
import { TemplateType } from '../pages/campaigns/legacyBuilder/WaterfallSelector';
import { CampaignConversionActionMap, ConversionAction } from '../types';
import { User } from '../types/users';
import { NotificationConfig } from './alerts';
import { timeUtils } from './timeUtils';
import userUtils from './userUtils';
import { Role } from '../types';
import { DEMO_ACCT_UIDS, DEMO_AGENCY_UID, DEV_TEST_UIDS } from '../constants';

const colors: any = {
	info: '#1890FF',
	success: '#6262F5',
	error: '#ff4d7e',
	warn: '#E89C56',
	warning: '#E89C56',
};

const defaultMessage: any = {
	success: 'Success',
	error: 'Error',
	warn: 'Warning',
	info: 'Info',
};

class PromiseAny {
	q: any[];
	constructor() {
		this.q = [];
	}

	push = (p: any) => this.q.push(p);

	execAll = () => Promise.all(this.q);

	exec = async () => {
		const all = await Promise.allSettled(this.q);
		this.q = [];
		return all.map((res) => (res.status === 'fulfilled' ? res.value : undefined));
	};
}

export const utils = {
	// ! token for testing with eps (not used for PUT/POST/DELETE, only GET requests or POST that just return data)
	TEST_UID: '', // '1099',
	TOKEN: '',
	templateTypes: ['pushTemplate', 'emailTemplate', 'templates', 'adTemplate', 'browserTemplate', 'snailMailTemplate'] as TemplateType[],
	uid: '' as string,
	realID: '',
	auth: new AuthService() as AuthService,
	user: { profile: { name: '' } } as User,
	dashData: {
		plans: [],
		posList: [],
	},
	asHost: '',
	params: {} as any,
	history: history,

	isDevUID: (uid?: string) => DEV_TEST_UIDS.includes(uid || utils.uid),
	isDemo: (uid?: string) => [...DEMO_ACCT_UIDS, ...DEV_TEST_UIDS].includes(uid || utils.uid) || utils.user.agencyID === DEMO_AGENCY_UID,
	AYR: ['1197', '1230', '1205', '1361', '1362', '1540', '1487', '1392', '1851', '1382', '2496', '2736'],
	hideVoiceConvs: ['1395', '1429', '1390', '1210'],

	isAYR: () => {
		const agencyID = '1196';
		return utils.AYR.includes(utils.uid) || utils.user.agencyID === agencyID;
	},
	isDispojoy: () => {
		const agencyID = '3579';
		return utils.uid === '3579' || utils.user.agencyID === agencyID;
	},
	isMessageDigitial: () => {
		const agencyID = '3490';
		return utils.uid === '3490' || utils.user.agencyID === agencyID;
	},
	specialPoliticalAgencies: ['3490', '3579'],
	isPolitical() {
		return utils.user?.profile?.industry === 'political' || utils.isMessageDigitial();
	},
	hasInfoBipCampaignID(user?: Partial<User>) {
		return !!(user ?? utils.user)?.infobipCmpID;
	},
	hasTelnyxCampaignID(user?: Partial<User>) {
		// This is enabled always for 1091 demo account
		if (utils.uid === '1091') return true;
		return !!(user ?? utils.user)?.tcmpID;
	},
	hasApprovedTelnyxCampaign(user?: Partial<User>) {
		if (utils.uid === '1091') return true;
		return (user ?? utils.user)?.approvedTelnyxCampaign;
	},
	hasRestrictedIndustry10DLC(user?: Partial<User>) {
		if (utils.isStagingDev() && utils.isDevUID()) return true;
		return !!utils.hasApprovedTelnyxCampaign(user) && !!utils.isRestrictedIndustry(user);
	},

	prodDomain: 'https://lab.alpineiq.com',
	stagingDomain: 'https://lab.aiqstaging.net',
	realDomain: () => {
		if (utils.isStagingDev()) return utils.stagingDomain;
		if (utils.isStaging()) return utils.stagingDomain;
		return utils.prodDomain;
	},
	apiDomain: () => {
		if (utils.isStagingDev()) return utils.stagingDomain;
		return '';
	},
	isLocal: () => location.hostname === 'localhost',
	isAlpineMain: () => location.hostname === 'lab.alpineiq.com',
	isStaging: () => location.hostname.includes('aiqstaging.net'),
	isStagingDev: () => location.hostname.includes('lab-dev.aiqstaging.net'),
	isVercelPreview: () => location.hostname?.includes('vercel.app'),
	isAPI: () => location.hostname.includes('api.alpineiq.com'),
	isShortyDomain(url?: string): boolean {
		if (utils.isLocal()) return true;
		if (utils.isStagingDev()) return false;
		if (utils.isAlpineMain()) return false;
		try {
			const parsedUrl = new URL(url || location.href);
			if (parsedUrl.protocol !== 'https:' && parsedUrl.protocol !== 'http:') {
				return false;
			}

			const domain = parsedUrl.hostname;
			if (!domain.endsWith('.com')) {
				return false;
			}

			const domainName = domain.replace('.com', '').replace(/^www\./, '');

			// Check overall domain name length (<= 10 characters)
			if (domainName.length > 10) {
				return false;
			}

			// Split by hyphen if present
			const parts = domainName.split('-');

			// Check that each part (if any) is short enough (no more than 7 characters)
			if (parts.length > 1) {
				for (const part of parts) {
					if (part.length > 7) {
						return false;
					}
				}
			}
			// Check for underscores, which are not allowed
			if (domainName.includes('_')) {
				return false;
			}

			return true;
		} catch (e) {
			// If the URL is invalid or can't be parsed, return false
			return false;
		}
	},
	// True if env is staging, stagingDev, or local
	isDevEnv: () => {
		return utils.isLocal() || utils.isStaging() || utils.isStagingDev();
	},
	getUIDFromPath: (path = location.pathname) => {
		const uid = path.match(/\d{4,5}/);
		return uid ? uid[0] : '';
	},
	getCorsRedirectByURL: (url = location.href) => {
		if (url.includes('/wallet')) {
			const uid = utils.getUIDFromPath(url);
			return `http://${uid}.w.alpineiq.com/`;
		}
		if (url.includes('/join')) {
			return url.replace(location.hostname, 'lab.alpineiq.com');
		}
		return null;
	},
	isSuperAdmin: () => utils.auth.isSuperAdmin(utils.auth.getRole()),
	isAdmin: () => utils.auth.isAdmin(utils.auth.getRole()),

	currentUser: () => (utils.auth.currentUser || {}) as User,
	initialUser: () => (utils.auth.initialUser || {}) as User,

	getSubType: () => utils.user?.subType || 'retail',
	isVertical: (str?: string) => (str || utils.getSubType()) === 'vertical',
	isRetailOnly: (str?: string) => (str || utils.getSubType()) === 'retail' || (str || utils.getSubType()) === undefined,
	isBrandOnly: (str?: string) => (str || utils.getSubType()) === 'brand',
	isFreemiumVertical: (str?: string) => (str || utils.getSubType()) === 'freemium-vertical',
	isFreemiumRetail: (str?: string) => (str || utils.getSubType()) === 'freemium-retail' || utils.isFreemiumVertical(),
	isFreemiumBrand: (str?: string) => (str || utils.getSubType()) === 'freemium-brand' || utils.isFreemiumVertical(),
	isSandbox: (str?: string) => (str || utils.getSubType()) === 'sandbox',

	isFreemium: (str?: string) => (str || utils.getSubType()).includes('freemium'),
	isBrand: (str?: string) =>
		utils.isBrandOnly(str) || utils.isVertical(str) || ['1034', '1683'].includes(utils.uid) || (utils.user?.fees?.brandLicenseCharge || 0) > 0,
	isRetail: (str?: string) => utils.isRetailOnly(str) || utils.isVertical(str) || ['1034'].includes(utils.uid),

	isCannabis: (user?: Partial<User>) => (user ?? utils.user).profile?.industry === 'cannabis',
	isBevAlc: (user?: Partial<User>) => (user ?? utils.user).profile?.industry === 'brewery',
	isRestrictedIndustry: (user?: Partial<User>) => utils.isCannabis(user) || utils.isBevAlc(user),

	isAgency: () => utils.user.type === 'agency',
	isAdminAgency: () => {
		return utils.uid === '1';
	},

	now: (useMoment = false) => (useMoment ? moment().unix() : Math.floor(new Date().getTime() / 1000)),

	isSubUser() {
		const p = utils.initialUser().profile;
		return !!p && p.isSub;
	},

	isSuper() {
		if (utils.realID !== utils.uid) return true;
		const { type } = utils.initialUser() || utils.currentUser() || { type: '' };
		return type === 'admin' || type === 'agency';
	},

	reload: async (path = '', wait = 0) => {
		/* eslint no-restricted-globals: 0 */
		const loc = path || location.pathname,
			hist = utils.history || history;
		if (wait === 0) {
			if (!path || path === location.pathname) {
				await hist.go(0);
			} else {
				await hist.replace(loc);
			}
		} else setTimeout(() => utils.reload(path), wait);
	},

	validLoginPath: (path: any) => {
		const inValidPaths = ['login', 'logout', 'resetpassword', 'forgotpassword', 'register'];
		if (inValidPaths.some((p) => utils.strify(path).includes(p))) return '';
		return path;
	},

	isCanadaBilling: () => {
		const fees = userUtils.getUserFees();
		const country = (fees.address?.country || utils.user?.address?.country || '').toLowerCase();
		return country === 'canada' || country === 'ca';
	},

	home: (u?: User) => {
		const user: User | undefined = u || utils.auth.currentUser || undefined;
		const role = utils.auth.getRole();
		const path = utils.validLoginPath(location.pathname + location.search);

		const loginURL = `/login${path ? `?p=${path}` : ''}`;
		if (!user) return loginURL;
		if (utils.auth.isSuperAdmin(role)) return '/admin';
		if (utils.auth.isAdmin(role)) return '/accountHealth/accounts';
		if (role === Role.BUDTENDER) return '/walletsearch/' + user.id;
		if (role === Role.MANAGER) return `/personas/manage/${user.id}`;
		if (role === Role.FINANCIAL) return `/settings/${user.id}/loyalty/reporting`;
		if (user.type === 'agency') return '/agency/' + user.id;
		if (user.type === 'advertiser') return '/missionControl/' + user.id;
		return loginURL;
	},

	getWalletURL: (uid: any, cnt: any) => {
		const kw = cnt.mobilePhone || cnt.homePhone || cnt.email || cnt.secondaryEmail;
		if (kw) return `/walletsearch/${uid}/${kw}`;
		return `/walletsearch/byID/${uid}/${cnt.contactID || encodeURIComponent(cnt.srcID)}`;
	},

	appendQueryParam: (k: any, v: any, replace = true) => {
		const pathname = location.pathname,
			query = new URLSearchParams(location.search);
		if (v === null || v === undefined) query.delete(k);
		else query.set(k, v);
		const o = {
			pathname,
			search: query.toString(),
		};
		if (replace) history.replace(o);
		else history.push(o);
	},

	queryParam: (p: any, s = location.search) => {
		const re = new RegExp('(?:[?&]|^)' + p + '=([^&]+)(?:[&]|$)'),
			m = s.match(re);
		return (m && m.length === 2 ? decodeURIComponent(decodeURI(m[1])) : '') as any;
	},

	showErr: (err: any, timeout?: any, debugOnly?: boolean, key?: string) => {
		if (!err || (debugOnly && !userUtils.debugMode())) return;
		console.error(typeof err, err);
		if (err instanceof Error) err = err.message;
		if (typeof err === 'object') {
			const all = utils
				.map(err, (v: any, k: any) => {
					if (v.error) return `${k}: ${v}`;
					let e = '';
					try {
						e = utils.map(v.errors, (v1: any) => v1.message).join('\n');
					} catch (err) {
						e = v;
					}
					return `${e}`;
				})
				.filter(Boolean);
			if (all.length) err = all.join('\n');
		}
		if (typeof err.toString === 'function') err = err.toString();
		if (typeof err !== 'string') err = JSON.stringify(err);
		// Ignore the openAI errors in cmp builder, can bb remove after we decide what to do w it
		if (err.includes(`https://platform.openai.com/account/api-keys`) && utils.isLocal()) return;
		if (err && err?.includes(`. Please try again in 30-60 minutes`)) err = `This action is temporarily unavailable. Please try again later`;
		if (err && err.includes('&texty')) return;
		utils.sendNotification({
			key,
			type: 'error',
			message: 'Error',
			description: utils.capitalize(err),
			duration: timeout || err.indexOf('://') > -1 ? 45 : 10,
			placement: debugOnly ? 'top' : 'topRight',
		});
	},

	showErrs: (errors: string[], prefix?: string) => {
		if (!errors.length) return;
		return utils.notify(
			prefix || `Error${errors.length ? 's' : ''} while saving`,
			errors.map((err: string, key) => (
				<div key={key}>
					{!!key ? (
						<Break dashed />
					) : (
						<Break
							air
							v="5"
						/>
					)}
					{err}
				</div>
			)),
			10,
			'error',
		);
	},

	showSuccess: (msg: any) => {
		if (!msg) return;
		if (typeof msg !== 'string') msg = JSON.stringify(msg);
		utils.notify('Success', msg);
	},

	setLoading: (comp: any, key: any, v: any) => comp.setState({ ...(comp.state || {}), [key]: v }),

	capitalize: (s: any) => {
		if (typeof s !== 'string') return '';
		return s.charAt(0).toUpperCase() + s.slice(1);
	},

	math: {
		percentOf: (a: any, b: any) => (a || 0) / (b || 0) || 0,
		percentDiff: (A: any, B = A) => 100 * Math.abs((A - B) / ((A + B) / 2)),
		realPercDiff: (new_value = 0, original_value = new_value) => ((new_value - original_value) / Math.abs(original_value)) * 100,
		percentInc: (A: any, B = A) => ((A - B) / B) * 100,
		// * Find variance and standardDeviation in array of numbers
		findVariance: (arr = [] as any[]) => {
			let mean: any = arr.reduce((acc, curr) => acc + curr, 0) / arr.length; // @ts-ignore
			arr = arr.map((k: any) => (k - mean) ** 2);
			let sum: any = arr.reduce((acc, curr) => acc + curr, 0),
				variance = sum / arr.length || 0,
				avgVariance = variance / arr.length || 0;
			return { variance, avgVariance, standardDeviation: Math.sqrt(sum / arr.length) || 0 };
		},
	},

	local: {
		setLocal: (key: any, value: any) => localStorage.setItem(`aiq:local:${key}`, typeof value === 'string' ? value : JSON.stringify(value || {})),
		getLocal: (key: any, backup?: any) => {
			const itemStr = localStorage.getItem(`aiq:local:${key}`);
			return itemStr !== null ? JSON.parse(itemStr) : backup;
		},
		deleteLocal: (key: any) => localStorage.removeItem(`aiq:local:${key}`),
		expiryTime: (x: number, v: 's' | 'm' | 'h' | 'd') => {
			if (!x || !v) return x;
			if (v == 's') return x * 1000;
			if (v == 'm') return x * (60 * 1000);
			if (v == 'h') return x * 60 * (60 * 1000);
			if (v == 'd') return x * 24 * (60 * (60 * 1000));
			return x;
		},
		delWithExpirty: (key: string) => localStorage.removeItem(key?.replace(':uid', utils.uid)),
		setWithExpiry: (key: any, value: any, time: number) => {
			try {
				const now = new Date();
				const item = { value: value, expiry: now.getTime() + time };
				localStorage.setItem(key?.replace(':uid', utils.uid), JSON.stringify(item));
			} catch (err) {
				return null;
			}
		},
		getWithExpiry: (key: any) => {
			try {
				const itemStr = localStorage.getItem(key?.replace(':uid', utils.uid));
				if (!itemStr) return null;
				const item = JSON.parse(itemStr);
				const now = new Date();
				if (now.getTime() > item.expiry) {
					localStorage.removeItem(key?.replace(':uid', utils.uid));
					return null;
				}
				return item.value;
			} catch (err) {
				return null;
			}
		},
	},

	// Helper to parse the custom input value since it's converted to JSON
	parseValueOrUndefined: (v: any) => {
		if (typeof v === 'string')
			try {
				return JSON.parse(v);
			} catch (e) {}
		return v;
	},

	darkMode: (localStorage.getItem('aiq:local:darkMode') === 'true' || false) as boolean,
	toggleTheme: () => {
		// @ts-ignore
		localStorage.setItem('aiq:local:darkMode', !utils.darkMode);
		utils.reload();
	},

	fmtTS: (ts: any, short = false, slash = false, showAm = false, readable = false) => {
		if (!ts) return 'N/A';
		if (readable)
			return (ts instanceof Date ? ts : new Date(typeof ts === 'number' ? ts * 1000 : ts)).toLocaleString('default', {
				month: 'short',
				day: '2-digit',
				year: 'numeric',
				...(showAm ? { hour: '2-digit', minute: '2-digit' } : {}),
			});
		const fTs = moment
			.unix(ts)
			.local()
			.format(short ? 'YYYY-MM-DD' : `YYYY-MM-DD ${showAm ? 'hh:mm:a' : 'HH:mm'}`);
		return slash ? fTs.replaceAll('-', '/') : fTs;
	},
	fmtTSFlipped: (ts: any, def = '') => (ts > 0 ? moment.unix(ts).local().format('MM-DD-YYYY HH:mm') : def),
	fmtDateToEpoch: (date: any) => moment(date, 'YYYY-MM-DD HH:mm').valueOf(),
	fmtPrice: (am: any, curr: any, trim = false) => `${curr === 'USD' ? '$' : 'CA$'}${parseFloat(trim ? am : am / 100).toLocaleString()}`,

	unique: (arr: any) => {
		const o: any = {};
		for (const k of arr) o[k] = true;
		return Object.keys(o).sort();
	},

	forEach: (data: any, fn: any) => {
		if (!data || typeof data !== 'object') return;

		if (typeof data.forEach === 'function') return data.forEach(fn);

		for (const key of Object.keys(data)) {
			if (fn(data[key], key) === false) return;
		}
	},

	map: (data: any, fn: any) => {
		if (typeof data === 'string') {
			try {
				data = JSON.parse(data); // wtf man..
			} catch (err) {
				return data;
			}
		}
		if (!data || typeof data !== 'object') return data;
		if (typeof data.map === 'function') return data.map(fn);
		return Object.keys(data).map((k) => fn(data[k], k));
	},

	filterObj: (obj: any, fn: any) => {
		if (typeof obj !== 'object') return obj;
		const out: any = {};
		for (const [k, v] of Object.entries(obj)) {
			if (fn(k, v, obj)) out[k] = v;
		}
		return out;
	},

	toFixedLocale: (n?: any, acc = 2, money = false) =>
		n === null || n === undefined || isNaN(n)
			? money
				? '$0'
				: '0'
			: money
				? `$${parseFloat(n.toFixed(acc)).toLocaleString()}`
				: parseFloat(n.toFixed(acc)).toLocaleString(),

	/**
	 * Formats a number to a string with optional precision, currency symbol, handling for infinity values, and percentage symbol.
	 * @param num - The number to format. Defaults to `undefined`.
	 * @param options - Additional formatting options.
	 * @param options.precision - The number of decimal places to round to. Defaults to `2`.
	 * @param options.currency - Whether or not to include a currency symbol. Defaults to `false`.
	 * @param options.allowInfinity - Whether or not to allow infinity values. Defaults to `false`.
	 * @param options.percent - Whether or not to include a percentage symbol. Defaults to `false`.
	 * @returns The formatted number as a string.
	 */
	defaultNumberFormatter: {},
	formatNumber: (num?: any, options?: { precision?: number; currency?: boolean; allowInfinity?: boolean; percent?: boolean }): string => {
		options = { precision: 2, currency: false, allowInfinity: false, percent: false, ...options };
		const wrapPercent = (str: any) => (options?.percent ? `${str}%` : str);
		const wrapCurrency = (str: any) => (options?.currency ? `$${str}` : str);
		// Replace percents, commas, and dollar signs
		num = num?.toString().replace(/%|,|\$/g, '');
		if (num === Infinity && !options?.allowInfinity) return 'N/A';
		if (num === '' || num === null || num === undefined || isNaN(num)) return wrapCurrency(wrapPercent('0'));
		let formattedNumber = '0';
		try {
			if (typeof num === 'string') num = parseFloat(num);
			formattedNumber = parseFloat(num.toFixed(options?.precision)).toLocaleString();
		} catch (e) {
			console.log('num:', num);
			console.log('failed to parse number:', e);
		}
		return wrapCurrency(wrapPercent(formattedNumber));
	},
	doCompare: (a: any, b: any) => {
		if (a === b) return 0;
		if (a == null) return 1;
		if (typeof a === 'string') return a.localeCompare(b);
		return a > b ? 1 : -1;
	},

	replaceConversionLabel: (conversionActionName: any) => {
		let actions: { [key: string]: string } = (Object.keys(CampaignConversionActionMap) as ConversionAction[]).reduce(
			(acc, key) => ({ ...acc, [key]: CampaignConversionActionMap[key].description }),
			{},
		);
		return conversionActionName in actions ? actions[conversionActionName] : conversionActionName;
	},

	// @ts-ignore
	isPuppy: () => document.body.classList.has('puppy'),
	// emailRE: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,6})+$/,
	emailRE: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<,>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
	phoneRex: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
	// UUID RE, to find contactIDs / uuids
	uuidRE: /\b(uuid:){0,1}\s*([a-f0-9\\-]*){1}\s*/,

	getRandomArbitrary: (min = 0, max = 100) => Math.random() * (max - min) + min,
	strify,
	sorter: (fld: any = 'null', ascended = true) => {
		if (fld == 'null') fld = null;
		const ffn = (v: any) => {
			try {
				if (fld == null) return v;
				if (typeof fld === 'function') return fld(v);
				return v ? v[fld] : null;
			} catch (e) {
				console.warn(e);
				return null;
			}
		};

		const clean = (v: any) => {
			v = ffn(v);
			if (v == null) return null;
			if (typeof v === 'boolean') return v ? 1 : 0;
			if (typeof v === 'number' && isNaN(v)) return -1;
			if (typeof v === 'string') {
				// @ts-ignore
				if (isNaN(v)) return v;
				const fv = parseFloat(v);
				v = isNaN(fv) ? v : fv;
			}
			return v;
		};

		return ((a: any, b: any) => {
			let av = clean(ascended ? a : b),
				bv = clean(ascended ? b : a);

			if (bv == null && typeof av === 'number') bv = 0;
			if (av == null && typeof bv === 'number') av = 0;

			if (typeof av === 'number' && typeof bv === 'number') return av - bv;

			if (typeof av === 'string' || typeof bv === 'string') {
				av = (av + '' || '').toLocaleLowerCase();
				bv = (bv + '' || '').toLocaleLowerCase();
				return av.localeCompare(bv);
			}

			if (av < bv) return -1;
			if (av > bv) return 1;
			return 0;
		}) as any;
	},

	sort: (inp: any, fld = null as any, ascended = true) => (inp || []).sort(utils.sorter(fld, ascended)),

	equal: (a: any, b: any) => {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (typeof a !== 'object') return false;
		if (typeof a.valueOf === 'function' && typeof b.valueOf === 'function') return a.valueOf() === b.valueOf();

		const aks = Object.keys(a).sort(),
			blen = Object.keys(b).length;

		if (aks.length !== blen) return false;
		for (const k of aks) {
			if (!utils.equal(a[k], b[k])) return false;
		}

		return true;
	},
	// @ts-ignore
	removeDuplicates: (arr = []) => [...new Set(arr)],

	filterSelect: (inputValue: any, option: any) =>
		option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1 || option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1,

	clone: (object: any) => {
		try {
			if (object instanceof Date) return new Date(object);
			if (object == null || typeof object !== 'object' || '__clone' in object) {
				return object;
			}

			const parseify = (v: any) => JSON.parse(JSON.stringify(v));
			const cloneFunc = cloneDeep ?? structuredClone ?? parseify;
			return cloneFunc(object);
		} catch (err) {
			return cloneDeep(object);
		}
	},
	fileExists: async (fp: any) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.onerror = () => resolve(false);
			img.onload = () => resolve(true);
			img.src = fp;
		});
	},

	toCamel: (s: any) => {
		return s.replace(/([-_][a-z])/gi, ($1: any) => {
			return $1.toUpperCase().replace('-', '').replace('_', '');
		});
	},

	imageInfo: async (src: any) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;
			img.onload = () => resolve({ src, width: img.width, height: img.height });
			img.onerror = reject;
		});
	},

	readFile: (file: any, type = 'text') => {
		return new Promise((resolve, reject) => {
			const rd = new FileReader();

			rd.onload = () => resolve(rd.result);
			rd.onerror = reject;

			switch (type) {
				case 'text':
					return rd.readAsText(file); // @ts-ignore
				case 'b64image':
					rd.onload = () => utils.imageInfo(rd.result).then(resolve);
				// fallthrough
				case 'b64':
					return rd.readAsDataURL(file);
				default:
					return rd.readAsBinaryString(file);
			}
		});
	},

	loadFonts: (fonts: string[]) => {
		const all = (fonts || []).map((v: any) => v.replace(/\s+/g, '+')).join('|');
		const ele = document.createElement('link');
		ele.rel = 'stylesheet';
		ele.type = 'text/css';
		ele.href = `https://fonts.googleapis.com/css?family=${all}`;
		ele.media = 'all';
		document.head.appendChild(ele);
		return () => document.head.removeChild(ele);
	},

	merge: require('deepmerge'),

	trimObject,
	searchObject: (obj: any, search: any, config: any) => {
		if (!obj || !search) return false;

		const { vOnly = false, kOnly = false, recursion = true }: any = config;
		search = search.toLowerCase().trim();

		const keys = Object.keys(obj),
			values = Object.values(obj);
		let kcontains: any = false,
			vcontains: any = false;

		if (!vOnly) kcontains = keys.filter((str) => str.toLowerCase().includes(search)).length;
		if (!kOnly) {
			vcontains = values.filter((val: any) => {
				const type = typeof val;
				if (type === 'number') return val == search;
				if (type === 'string') return val.toLowerCase().includes(search);
				if (type === 'object') {
					// if (!recursion)
					return JSON.stringify(obj).toLowerCase().includes(search);
					// return searchObject(obj, search, config)
				}
			}).length;
		}

		return kcontains || vcontains;
	},

	getTooltip: (text: any, tooltip: any) => <Tooltip title={tooltip}>{text}</Tooltip>,

	addStyle: (css: any, id: any = null) => {
		id = id || `css${css.length}`;
		let ele: any = document.getElementById(id),
			found = !!ele;
		if (!found) {
			ele = document.createElement('style');
			ele.rel = 'text/css';
			ele.media = 'all';
			ele.id = id;
		} else {
			ele.innerText = '';
		}
		ele.appendChild(document.createTextNode(css));
		if (!found) document.body.appendChild(ele);
	},

	getCookie: (key: any) => {
		return (
			document.cookie
				?.split('; ')
				?.find((row) => row.startsWith(`${key}=`))
				?.split('=')[1] || ''
		);
	},

	loadScript: (id: string, src: string, once = true) => {
		// Check if the script is already loaded
		if (once && document.head.querySelector('#' + id) != null) return;

		// Create a new script element
		const ele = document.createElement('script');
		if (id) ele.setAttribute('id', id);
		ele.setAttribute('src', src);

		ele.onload = () => {
			if (userUtils.debugMode()) console.info(`Script with ID '${id}' loaded successfully.`);
		};

		ele.onerror = (error) => {
			if (userUtils.debugMode()) console.info(`Failed to load script with ID '${id}':`, error);
		};

		// Append the script element to the document head
		document.head.appendChild(ele);
	},

	removeScript: (id: any, noRefresh?: boolean): any => {
		const ele = document.getElementById(id);
		if (ele) {
			try {
				ele.remove();
			} catch (err) {
				console.error(err);
			}
			if (!noRefresh) utils.reload();
		}
	},

	centerRow: (children: any) => (
		<Row
			justify="center"
			style={{ width: '100%' }}
		>
			{children}
		</Row>
	),

	noPadding: { paddingBottom: 0, paddingLeft: 0, paddingRight: 0, paddingTop: 0 },
	noMargin: { marginBottom: 0, marginLeft: 0, marginRight: 0, marginTop: 0 },

	getBrandSettings: (st: any) => {
		if (st && Object.keys(st).length > 0) {
			st = { ...st, __set: true };
		} else {
			st = {};
		}
		if (!st.css) st.css = {};
		if (!st.fonts) st.fonts = [];
		if (!st.icons) st.icons = {};
		if (!st.toggles) st.toggles = {};
		if (!st.data) st.data = {};
		for (const k of Object.keys(st.css)) {
			let v = st.css[k];
			if (typeof v === 'string') continue;
			st.css[k] = v = { ...v };
			for (const k of Object.keys(v)) {
				if (k.indexOf('-') === -1) continue;
				v[utils.toCamel(k)] = v[k];
				delete v[k];
			}
		}
		return st;
	},

	cssToText: (v: any) => {
		if (!v) return '';
		if (typeof v !== 'object') return v;
		const out = [];
		for (const k of Object.keys(v)) {
			const ckey = k.replace(/([A-Z])/g, (m) => '-' + m.toLowerCase());
			out.push(`${ckey}: ${v[k]};\n`);
		}
		return out.length ? out.join('') : '';
	},

	hackCSS: (css: any) => {
		const extraCSS = [];
		if (css.borderColor) {
			extraCSS.push(`
			.ccss .ant-tabs-top > .ant-tabs-nav::before, .ccss .ant-tabs-bottom > .ant-tabs-nav::before,
			.ccss .ant-tabs-top > div > .ant-tabs-nav::before, .ccss .ant-tabs-bottom > div > .ant-tabs-nav::before {
				border-bottom: 1px solid ${css.borderColor};
			}
			.ccss .ant-divider-horizontal.ant-divider-with-text::before, .ccss .ant-divider-horizontal.ant-divider-with-text::after {
				border-top: 1px solid ${css.borderColor};
			}
			.ccss .ant-tabs-tab-active {
				border: 1px solid ${css.borderColor} !important;
				border-bottom: 1px solid ${css.borderColor} !important;
				background-color: inherit !important;
			}
			.ccss .ant-divider { border-color: ${css.borderColor}; }
			.ccss .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn { color: ${css.borderColor} }
			`);
		}

		// if (css.progressColor) extraCSS.push(`.ant-progress-success-bg, .ant-progress-bg { background-color: ${css.progressColor}; }`);
		if (css.progressSuccessColor) extraCSS.push(`.ccss .ant-progress-status-success .ant-progress-text { color: ${css.progressSuccessColor}; }`);

		if (css.body) {
			extraCSS.push(`
			.ccss {
				${utils.cssToText(css.body)}
			}
			`);
		}
		if (css.button) {
			extraCSS.push(`
			.ccss .ant-btn {
				${utils.cssToText(css.button)};
			}
			`);
		}
		if (css.disabledButton) {
			extraCSS.push(`
			.ccss .ant-btn[disabled] {
				${utils.cssToText(css.disabledButton)};
			}
			`);
		}
		if (css.card) {
			extraCSS.push(`
			.ccss .ant-card {
				${utils.cssToText(css.card)}
			}
			`);
		}
		if (css.cover) {
			extraCSS.push(`
			.ccss .coverRow {
				${utils.cssToText(css.cover)}
			}
			`);
		}
		if (css.h1) {
			extraCSS.push(`
			.ccss .storeName, .ccss .totalPoints, .ccss .clientName {
				${utils.cssToText(css.h1)}
			}
			`);
		}
		if (css.override) extraCSS.push(css.override);
		utils.addStyle(PublicCSS, 'publicCSS');
		if (extraCSS.length) utils.addStyle(extraCSS.join('\n'), 'extraCSS');
	},

	sleep: (ms: number) => new Promise((r) => setTimeout(r, ms)),

	sortArrObjectKeys: (arr?: any, ignoreTS?: any) =>
		arr
			.map((item: any) =>
				typeof item === 'object' ? (Array.isArray(item) ? (item = utils.sortArrObjectKeys(item, ignoreTS)) : (item = utils.sortObjectKeys(item, ignoreTS))) : item,
			)
			.sort(),
	sortObjectKeys: (object?: any, ignoreTS?: any) =>
		Object.keys(object)
			.sort()
			.reduce((obj: any, key) => {
				let sortedKey: any = object[key];
				if (!!sortedKey && typeof sortedKey === 'object')
					Array.isArray(sortedKey) ? (sortedKey = utils.sortArrObjectKeys(sortedKey, ignoreTS)) : (sortedKey = utils.sortObjectKeys(sortedKey, ignoreTS));
				if (!ignoreTS || key !== 'timestamp') obj[key] = sortedKey;
				return obj;
			}, {}),

	// @ts-ignore
	getUUID: () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)),

	// If the target is found, it is removed from the array and added to the front
	moveStringToFront: (arr: string[], target: string, moveAs?: string) => {
		const index = arr.indexOf(target),
			v: any = index !== -1 ? arr?.splice(index, 1)[0] : undefined;
		return index !== -1 ? [moveAs || v, ...arr] : arr;
	},

	// Define base64 characters
	chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',

	// Function to convert Base64 to string
	base64ToString(input: any) {
		if (!input) return '';
		const padding = input.endsWith('==') ? 2 : input.endsWith('=') ? 1 : 0;
		const length = (input.length / 4) * 3 - padding;
		const data = new Uint8Array(length);
		const characters = utils.chars;
		for (let i = 0, j = 0; i < length; j += 4) {
			const [a, b, c, d] = [characters.indexOf(input[j]), characters.indexOf(input[j + 1]), characters.indexOf(input[j + 2]), characters.indexOf(input[j + 3])];
			data[i++] = (a << 2) | (b >> 4);
			if (i < length) data[i++] = ((b & 15) << 4) | (c >> 2);
			if (i < length) data[i++] = ((c & 3) << 6) | d;
		}
		const decoder = new TextDecoder();
		return decoder.decode(data);
	},

	// Function to convert string to Base64
	stringToBase64(input: any) {
		if (!input) return '';
		const encoder = new TextEncoder();
		const data = encoder.encode(input);
		let base64 = '';
		const characters = utils.chars;
		const padding = data.length % 3;
		for (let i = 0; i < data.length - padding; i += 3) {
			const [a, b, c] = [data[i], data[i + 1], data[i + 2]];
			base64 += characters[a >> 2] + characters[((a & 3) << 4) | (b >> 4)] + characters[((b & 15) << 2) | (c >> 6)] + characters[c & 63];
		}
		if (padding === 2) {
			const [a, b] = [data[data.length - 2], data[data.length - 1]];
			base64 += characters[a >> 2] + characters[((a & 3) << 4) | (b >> 4)] + characters[(b & 15) << 2] + '=';
		} else if (padding === 1) {
			const a = data[data.length - 1];
			base64 += characters[a >> 2] + characters[(a & 3) << 4] + '==';
		}
		return base64;
	},

	// Your functions using the above methods
	encodeObject(object: any) {
		try {
			const jsonString = JSON.stringify(object);
			const base64String = utils.stringToBase64(jsonString);
			const encoded = encodeURIComponent(base64String);
			return encoded;
		} catch (error) {
			if (utils.isAdmin()) utils.showErr(error);
			return undefined;
		}
	},

	decodeObject(encodedString: any) {
		try {
			if (encodedString === '{}') return {};
			const decoded = decodeURIComponent(encodedString);
			const base64String = utils.base64ToString(decoded);
			const object = JSON.parse(base64String);
			return object;
		} catch (error) {
			if (utils.isAdmin()) utils.showErr(error);
			return undefined;
		}
	},

	secondsToHms: (d: any) => {
		d = Number(d);
		let h = Math.floor(d / 3600);
		let m = Math.floor((d % 3600) / 60);
		let s = Math.floor((d % 3600) % 60);

		let hDisplay = h > 0 ? h + (h == 1 ? ' hour, ' : ` hours${m > 0 ? ',' : ''} `) : '';
		let mDisplay = m > 0 ? m + (m == 1 ? ' minute, ' : ` minutes${s > 0 ? ',' : ''} `) : '';
		let sDisplay = s > 0 ? s + (s == 1 ? ' second' : ' seconds') : '';
		return hDisplay + mDisplay + sDisplay;
	},
	loadImage: (cb: any, imageUrl: any) => {
		const img = new Image();
		img.src = imageUrl;
		img.onload = () => cb({ height: img.height, width: img.width });
		img.onerror = (err) => utils.showErr(err);
		return;
	},
	loadImageFromFile: async (cb: any, file: any) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = async () => {
				try {
					const image: any = new Image();
					image.onload = async () => {
						cb(image.height, image.width);
						resolve(true);
					};
					image.src = reader.result;
				} catch (err) {
					reject(err);
				}
			};
			reader.onerror = (error) => {
				reject(error);
			};
			reader.readAsDataURL(file);
		});
	},

	loadImageDimensionsFromFile: (file: File): Promise<{ width: number; height: number }> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = (event) => {
				const image = new Image();
				image.onload = () => {
					resolve({ width: image.width, height: image.height });
				};
				image.onerror = reject;
				image.src = event.target?.result as string;
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		});
	},

	loadImageDimensionsFromURL: (url: string): Promise<{ width: number; height: number }> => {
		return new Promise((resolve, reject) => {
			const image = new Image();
			image.onload = () => {
				resolve({ width: image.width, height: image.height });
			};
			image.onerror = reject;
			image.src = url;
		});
	},

	// Triggers a download of fileName with data as mimeType.
	downloadData: async (data: any, fileName: any, mimeType: any) => {
		const file = new File([data], fileName, { type: mimeType });
		let link = document.createElement('a');
		const url = URL.createObjectURL(file);
		link.href = url;
		link.download = file.name;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		window.URL.revokeObjectURL(url);
	},

	// pass async func in useEffect to make my life eaiser
	asyncFetch: async (
		cb: () => any,
		errCb?: (err: any) => void,
		cancel?: boolean,
		results?: boolean,
		options = {
			performance: false,
		},
	) => {
		try {
			const callBack = options.performance ? timeUtils.timeAsync(cb) : cb;
			if (results) return await callBack();
			else await callBack();
			return false;
		} catch (error) {
			if (!cancel) utils.showErr(error);
			if (errCb) {
				if (results) return errCb(error);
				else errCb(error);
			}
			if (results) return null;
			return true;
		}
	},

	// * Get notifications queued to send after reloading page
	getNotifyQueue: () => utils.local.getLocal('notify:queue', {}),
	addNotifyQueue: (type: any, notify: any) => utils.local.setLocal('notify:queue', { type, notify }),
	delNotifyQueue: () => utils.local.setLocal('notify:queue', {}),

	notify: (message: any, description?: any, duration?: any, type = 'success', config = {} as any) => {
		const notify = {
			key: utils.strify(description),
			style: { borderLeft: colors[type] ? `3px solid ${colors[type]}` : '' },
			message: typeof message === 'string' ? <span style={{ fontSize: '1rem', fontWeight: 600 }}>{message}</span> : message,
			description: typeof description === 'string' ? <span style={{ fontSize: '.875rem', fontWeight: 400 }}>{description}</span> : description,
			duration: duration,
			maxCount: 1,
			...config,
		};
		if (config?.queue === true) utils.addNotifyQueue(type, notify);
		// @ts-ignore
		notification[type](notify);
	},
	formatTime: (mili: any, includeMs = false) => {
		const pad = (n: any, z = 2) => ('0' + n).slice(-z);
		const h = pad((mili / 3.6e6) | 0),
			m = pad(((mili % 3.6e6) / 6e4) | 0),
			s = pad(((mili % 6e4) / 1000) | 0),
			ms = pad(mili % 1000, 3);
		return (
			(h != '00' ? h + 'h:' : '') +
			(m != '00' ? m + 'm:' : '') +
			(s != '00' ? s + (includeMs ? '.' : 's') : '') +
			(includeMs ? '' + ms + (s != '00' ? 's' : 'ms') : '')
		);
	},

	inIFrame: () => {
		try {
			return window.location !== window.parent.location;
		} catch (_) {
			// security error === iframe
			return true;
		}
	},

	promiseQ: () => new PromiseAny(),

	calculateRatio: (numerator: number, denominator: number): string => {
		for (let num = denominator; num > 1; num--) {
			if (numerator % num === 0 && denominator % num === 0) {
				numerator /= num;
				denominator /= num;
			}
		}

		return `${numerator}:${denominator}`;
	},

	isStandalone: () => {
		try {
			// @ts-ignore
			return !!window?.navigator?.standalone || window.matchMedia('(display-mode: standalone)').matches;
		} catch (err) {
			console.warn(err);
			return false;
		}
	},

	getUserAgent: () => {
		try {
			return window.navigator.userAgent;
		} catch (err) {
			console.error(err);
		}
		return '';
	},

	/**
	 * useScript loads an external script and returns the status.
	 * @param {string} src
	 * @return {string} [ "idle", "loading", "ready", "error" ]
	 */
	useScript: (src: any) => {
		const [status, setStatus] = useState(src ? 'loading' : 'idle');

		useEffect(() => {
			// Allow falsy src value if waiting on other data needed for
			// constructing the script URL passed to this hook.
			if (!src) {
				setStatus('idle');
				return;
			}

			// Fetch existing script element by src
			// It may have been added by another intance of this hook
			let script: any = document.querySelector(`script[src="${src}"]`);

			if (!script) {
				// Create script
				script = document.createElement('script');
				script.src = src;
				script.async = true;
				script.setAttribute('data-status', 'loading');
				// Add script to document body
				document.body.appendChild(script);

				// Store status in attribute on script
				// This can be read by other instances of this hook
				const setAttributeFromEvent = (event: any) => {
					script.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error');
				};

				script.addEventListener('load', setAttributeFromEvent);
				script.addEventListener('error', setAttributeFromEvent);
			} else {
				// Grab existing script status from attribute and set to state.
				setStatus(script.getAttribute('data-status'));
			}

			// Script event handler to update status in state
			// Note: Even if the script already exists we still need to add
			// event handlers to update the state for *this* hook instance.
			const setStateFromEvent = (event: any) => {
				setStatus(event.type === 'load' ? 'ready' : 'error');
			};

			// Add event listeners
			script.addEventListener('load', setStateFromEvent);
			script.addEventListener('error', setStateFromEvent);

			// Remove event listeners on cleanup
			return () => {
				if (script) {
					script.removeEventListener('load', setStateFromEvent);
					script.removeEventListener('error', setStateFromEvent);
				}
			};
		}, [src]); // Only re-run effect if script src changes

		return status;
	},

	isMarkdown: (str: string) => /(\*\*|__|\*|_|`|~|\[.*\]\(.*\)|!\[.*\]\(.*\)|#{1,6}\s|\n\d+\.(\s|$)|\n[-+*]\s|\n`{3}.*\n|>\s|\|.*\|[-:| ]*)/.test(str),

	sendNotification: (config = {} as Partial<NotificationConfig>) => {
		const type = config.type || 'success';
		const description = config.description || '',
			message = config.message || defaultMessage[type];
		const color = config?.color || colors[type] || '';
		notification[type]({
			...config,
			duration: config.duration ?? 8,
			maxCount: config.maxCount || 1,
			style: { borderLeft: color ? `3px solid ${color}` : '', ...config.style },
			message: typeof message === 'string' ? <span style={{ fontSize: '1rem', fontWeight: 600 }}>{message}</span> : message,
			description: typeof description === 'string' ? <span style={{ fontSize: '.875rem', fontWeight: 400 }}>{description}</span> : description,
			key: config.key || utils.strify(description),
		});
	},

	// reduce an array of objects into an object with the id as the key
	reduceByID: (arr: any) => {
		if (!arr) return {};
		if (!Array.isArray(arr)) return {};
		return arr.reduce((acc: any, cur: any) => ({ ...acc, [cur.id]: cur }), {});
	},

	getUrlSearchParams: (url: string): Params => {
		const params: Params = {};
		new URL(url, location.origin).searchParams.forEach((value, key) => (params[key] = value));
		return params;
	},
	updateUrlSearchParams: (url: string, params: Params): string => {
		const urlObj = new URL(url, location.origin);
		Object.entries(params).forEach(([key, value]) => {
			value === null ? urlObj.searchParams.delete(key) : urlObj.searchParams.set(key, value);
		});
		return urlObj.href;
	},

	// getPropertyByPath: Retrieves the value of a nested property from an object using a dot-separated path.
	// Parameters:
	//   - obj: The object to traverse.
	//   - path: The dot-separated path to the desired property.
	//   - delimiter: The delimiter used to separate path segments. Defaults to '.'.
	// Returns:
	//   - The value of the property if found, or undefined if the property or any intermediate object in the path is undefined.
	getPropertyByPath(obj: any, path: string, delimiter = '.'): any {
		const keys: string[] = path.split(delimiter);
		let value: any = obj;
		for (const key of keys) {
			value = value?.[key];
			if (value === undefined) {
				return undefined;
			}
		}
		return value;
	},
	// setPropertyByPath: Sets the value of a nested property in an object using a dot-separated path.
	// If intermediate objects in the path do not exist, they are created as empty objects.
	// Parameters:
	//   - obj: The object to traverse and modify.
	//   - path: The dot-separated path to the target property.
	//   - value: The value to set at the target property.
	//   - delimiter: The delimiter used to separate path segments. Defaults to '.'.
	setPropertyByPath(obj: any, path: string, value: any, delimiter = '.'): any {
		const keys: string[] = path.split(delimiter);
		const lastKey: string = keys.pop()!;
		let target: any = obj;
		for (const key of keys) {
			if (target[key] === undefined) {
				target[key] = {};
			}
			target = target[key];
		}
		target[lastKey] = value;
		return obj;
	},

	tagRender: (props: any) => {
		const { label, value, closable, onClose } = props;
		if (value === '-1' || value === 'all')
			return (
				<Tag
					color="#303030"
					style={{ color: '#E3F526' }}
				>
					<Space>
						<i className="fa-light fa-star" />
						{label}
						{closable && (
							<i
								className="fa-light fa-xmark"
								style={{ marginBottom: -1, fontSize: '.75rem', cursor: 'pointer' }}
								onClick={onClose}
							/>
						)}
					</Space>
				</Tag>
			);

		return (
			<Tag
				color="#303030"
				style={{ color: '#fff' }}
			>
				<Space size="small">
					{label}
					{closable && (
						<i
							className="fa-light fa-xmark"
							style={{ marginBottom: -1, fontSize: '.75rem', cursor: 'pointer' }}
							onClick={onClose}
						/>
					)}
				</Space>
			</Tag>
		);
	},

	pickDate: (d: moment.Moment, index: number, asString?: boolean): string | number | undefined => {
		if (!d) return asString ? '' : undefined;
		const f = index === 0 ? d?.startOf('day') : d?.endOf('day');
		return asString ? f?.toISOString() : f?.unix();
	},

	add: (...args: number[]): number => args.reduce((a, b) => (a || 0) + (b || 0), 0),

	isRegexPattern(input: string | undefined): { valid: boolean; error?: string } {
		if (!input) return { valid: false, error: 'No input' };
		try {
			new RegExp(input);
			return { valid: true };
		} catch (error) {
			return { valid: false, error: `Invalid regex pattern. ${error?.toString()}` };
		}
	},

	log(args: string[] | [string, string][] | ({ [key: string]: any } | any)): void {
		try {
			console.log('%c--------------------%cutils%c--------------------', 'color: green', 'color: aqua', 'color: green');
			if (Array.isArray(args)) {
				for (const arg of args) {
					if (Array.isArray(arg)) {
						console.log(`${arg[0]}:`, arg[1]);
					} else {
						console.log(arg);
					}
				}
			} else if (typeof args === 'object') {
				for (const [key, value] of Object.entries(args)) {
					console.log(`${key}:`, value);
				}
			}
			if (userUtils.debugMode() && userUtils.isExperimental() && !utils.isLocal()) {
				const stackInfo = new Error().stack?.split('\n')?.map((str) => {
					const [func, path] = str.split('@http'),
						line = `${str}`.match(/:\d+:\d+/)?.[0];
					return { func, path: `http${path}`, line };
				});
				console.log('StackInfo:', stackInfo);
			}
			console.log('%c--------------------%cutils%c--------------------', 'color: green', 'color: aqua', 'color: green');
		} catch (err) {}
	},

	filterOption,
};

function strify(value: any): string {
	try {
		if (typeof value === 'number') value = value.toString();
		if (typeof value === 'string') return value.toString().replace(/\s+/g, '').toLowerCase().trim();
		if (React.isValidElement(value)) {
			if ((value.props as any)?.text) return utils.strify((value.props as any)?.text?.toString());
			if (value.key) return utils.strify(value.key.toString());
			return utils.strify(value.toString());
		}
		if (Array.isArray(value) || (typeof value === 'object' && value !== null)) return JSON.stringify(value || {});
		return value || '';
	} catch (e) {
		return '';
	}
}

function filterOption(inputValue: string | undefined, option: any | any[]): boolean {
	if (Array.isArray(option)) {
		return !!(option || []).some((option) => filterOption(inputValue, option));
	}
	const qv: string[] = [option.value, option.label].filter((x) => x !== undefined).map((x: any) => utils.strify(x));
	return !!qv.find((x) => x.includes(utils.strify(inputValue)));
}

export interface Params {
	[key: string]: string;
}

const isEvent = (e: any) => e instanceof Object && 'target' in e;

export const binder = (key: any, opts: any, overrides: any, store?: boolean) => {
	opts = {
		eventName: 'onChange',
		valueName: 'value',
		parse: null,
		...opts,
		...overrides,
	};

	if (!opts.getData || !opts.setData) throw new Error('missing get/setData');
	if (opts.number && !opts.parse) opts.parse = (v: any) => parseInt(v);
	if (opts.float && !opts.parse) opts.parse = (v: any) => parseFloat(v);
	if (opts.color) opts.valueName = 'color';
	if (opts.bool) opts.valueName = 'checked';

	const dirs = key.split('/'),
		finDir = dirs[dirs.length - 1],
		getVal = (data: any, parent = false) => {
			let o = data;
			if (key === '') return o;
			for (let i = 0; i < dirs.length - 1; i++) {
				const dir = dirs[i],
					tmp = o[dir];
				o[dir] = { ...tmp };
				o = o[dir];
			}
			if (parent) return o;

			let updated = false;
			if (overrides?.value === 'custom' && overrides?.realStyle) {
				let ob = overrides?.realStyle;
				for (let i = 0; i < dirs.length - 1; i++) {
					const dir = dirs[i],
						tmp = ob[dir];
					ob[dir] = { ...tmp };
					ob = ob[dir];
				}
				overrides.value = ob[finDir];
				updated = true;
			}

			if ((overrides?.value === 'custom' || updated) && overrides?.defaultStyle) {
				let ob = overrides?.defaultStyle;
				for (let i = 0; i < dirs.length - 1; i++) {
					const dir = dirs[i],
						tmp = ob[dir];
					ob[dir] = { ...tmp };
					ob = ob[dir];
				}
				opts.def = ob[finDir];
			}

			return o[finDir] ?? overrides?.value;
		};

	const out: any = {
		[opts.valueName]: getVal(opts.getData(store)),
		[opts.eventName]: (e: any) => {
			const { ...data } = opts.getData(store),
				o = getVal(data, true);

			let v = isEvent(e) ? e.target.value : e;
			if (v == null) {
				if (opts.alwaysParse && opts.parse) opts.parse(v, o[finDir]);
				delete o[finDir];
			} else {
				if (v.hex) v = v.hex;
				if (opts.parse) v = opts.parse(v, o[finDir]);
				o[finDir] = v;
			}
			if (userUtils.debugMode()) {
				console.debug(key, e, v, o[finDir], o);
			}
			opts.setData(data, store);
		},
	};

	if (opts.def) out.def = opts.def;

	if (opts.color) {
		out.styles = { default: { card: { background: out[opts.valueName] } } };
	}

	return out;
};

function trimObject<T = any & object>(obj: T, emptyString = false, makeCopy = false, emptyVals = false, recursive = false): T {
	const copy: T = utils.clone(obj) as any;
	if (typeof obj !== 'object' || obj === null) return obj;
	if (makeCopy) return utils.trimObject(copy, emptyString);
	else
		(Object.keys(obj) as Array<keyof T>).forEach((key: keyof T) => {
			if (obj[key] === undefined) {
				delete obj[key];
				return;
			}

			if (emptyVals && (obj[key] === null || obj[key] === 0)) {
				delete obj[key];
				return;
			}

			if (emptyVals && typeof obj[key] === 'object') {
				// Is it an array?
				if (Array.isArray(obj[key])) {
					if ((obj[key] as any[]).length === 0) {
						delete obj[key];
						return;
					}
					return;
				}

				const keys = Object.keys(obj[key] as any);
				if (keys.length === 0) {
					delete obj[key];
					return;
				}
				if (recursive) {
					obj[key] = utils.trimObject(obj[key], emptyString, false, emptyVals, recursive);
				}
				return;
			}
			if (typeof obj[key] === 'string') {
				const cleaned = (obj[key] as string).trim();
				if (emptyString && cleaned === '') {
					delete obj[key];
					return;
				}
				return;
			}
		});
	return makeCopy ? copy : obj;
}

// Get change set between two objects
export const deepDiffMapper = (function () {
	return {
		VALUE_CREATED: 'created',
		VALUE_UPDATED: 'updated',
		VALUE_DELETED: 'deleted',
		VALUE_UNCHANGED: 'unchanged',
		map: (obj1: any, obj2: any) => {
			// @ts-ignore
			if (this.isFunction(obj1) || this.isFunction(obj2)) return {};
			// @ts-ignore
			if (this.isValue(obj1) || this.isValue(obj2)) {
				return {
					// @ts-ignore
					type: this.compareValues(obj1, obj2),
					data: obj1 === undefined ? obj2 : obj1,
				};
			}

			let diff = {};
			for (const key in obj1) {
				// @ts-ignore
				if (this.isFunction(obj1[key])) continue;
				let value2: any = undefined;
				if (obj2[key] !== undefined) value2 = obj2[key]; // @ts-ignore
				diff[key] = this.map(obj1[key], value2);
			}
			for (const key in obj2) {
				// @ts-ignore
				if (this.isFunction(obj2[key]) || diff[key] !== undefined) continue; // @ts-ignore
				diff[key] = this.map(undefined, obj2[key]);
			}
			return diff;
		},
		compareValues: (value1: any, value2: any) => {
			// @ts-ignore
			if (value1 === value2) return this.VALUE_UNCHANGED; // @ts-ignore
			if (value1 === undefined) return this.VALUE_CREATED; // @ts-ignore
			if (value2 === undefined) this.VALUE_DELETED; // @ts-ignore
			if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
				// @ts-ignore
				return this.VALUE_UNCHANGED;
			} // @ts-ignore
			return this.VALUE_UPDATED;
		},
		isFunction: (x: any) => Object.prototype.toString.call(x) === '[object Function]',
		isArray: (x: any) => Object.prototype.toString.call(x) === '[object Array]',
		isDate: (x: any) => Object.prototype.toString.call(x) === '[object Date]',
		isObject: (x: any) => Object.prototype.toString.call(x) === '[object Object]', // @ts-ignore
		isValue: (x: any) => !this.isObject(x) && !this.isArray(x),
	} as any;
})();

export const mockSlowImport = <T extends React.ComponentType<any>>(
	factory: () => Promise<{ default: T }>,
	delay?: number, // Delay in milliseconds
): (() => Promise<{ default: T }>) => {
	if (!utils.isLocal() || !userUtils.debugMode()) return factory;
	if (!delay) delay = Math.floor(Math.random() * 4000) + 1000;
	return () =>
		new Promise((resolve) => {
			setTimeout(() => {
				resolve(factory());
			}, delay);
		});
};

// Get a value from an object based on a path string
export function getValueByPath<T>(obj: any, path: string, defaultValue?: T): T | undefined {
	const keys = path.split('.');
	let value: any = obj;

	for (const key of keys) {
		if (value && typeof value === 'object') {
			value = (value as Record<string, unknown>)[key];
		} else {
			return defaultValue;
		}
	}

	return value ?? defaultValue;
}

// Set a value on an object based on a path string
export function setValueByPath(obj: any, path: string, value: any) {
	const keys = path.split('.');
	let current = obj ?? {};

	for (let i = 0; i < keys.length; i++) {
		const key = keys[i];
		if (i === keys.length - 1) {
			current[key] = value;
		} else {
			if (!current[key]) {
				current[key] = {};
			}
			current = current[key];
		}
	}
}

export function getErrorMessage(error: unknown): string {
	if (error instanceof Error) {
		return error.message;
	}

	if (typeof error === 'string') {
		return error;
	}

	return 'An error occurred';
}

export function isChromiumBased() {
	var userAgent = navigator.userAgent;
	return userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Chromium') === -1;
}

export default utils;
// @ts-ignore
window['API'] = utils;
// @ts-ignore
window['aget'] = (ep: string, opts: any) => {
	utils.auth.get(ep, opts).then((res: any) => console.log(res));
};
// @ts-ignore
window['aput'] = (ep: string, opts: any) => {
	utils.auth.put(ep, opts).then((res: any) => console.log(res));
};
// @ts-ignore
window['apost'] = (ep: string, opts: any) => {
	utils.auth.post(ep, opts).then((res: any) => console.log(res));
};
// @ts-ignore
window['adelete'] = (ep: string, opts: any) => {
	utils.auth.delete(ep, opts).then((res: any) => console.log(res));
};
// @ts-ignore
window['aauth'] = () => console.log(utils.auth.getToken());
